import {Categories} from "../IconBaseClass";
/*
    PointToManyPoints

    These are methods of travel that have more than one stopping place, this made displaying
    Stuff like charter ships much more appealing

 */
export const PointToManyPoints = [
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.BOATS + ' charter_ship',
        stops: [
            {"lat": 80.13834056838962, "lng": 348.67309570312506},
            {"lat": 80.13834056838962, "lng": 348.67309570312506},
            {"lat": 71.08474366259543, "lng": 346.497802734375},
            {"lat": 73.12653976036125, "lng": 302.33276367187506},
            {"lat": 75.91886229511059, "lng": 304.83764648437506},
            {"lat": 75.37837872661018, "lng": 299.37194824218756},
            {"lat": 79.09573797855488, "lng": 288.62182617187506},
            {"lat": 76.65318152537525, "lng": 286.58386230468756},
            {"lat": 75.12386410829582, "lng": 280.84899902343756},
            {"lat": 69.29696896468664, "lng": 275.30090332031256},
            {"lat": 74.78449550320545, "lng": 245.78063964843753}
        ],
        positions: [
            {"lat": 80.13834056838962, "lng": 348.67309570312506},
            {"lat": 80.141162608798, "lng": 349.91455078125},
            {"lat": 78.98818668348777, "lng": 353.91357421875006},
            {"lat": 73.87371654457475, "lng": 358.96728515625006},
            {"lat": 70.90226826757711, "lng": 358.84643554687506},
            {"lat": 70.71809882433223, "lng": 346.45385742187506},
            {"lat": 71.08474366259543, "lng": 346.497802734375},
            {"lat": 70.71809882433223, "lng": 346.45385742187506},
            {"lat": 62.895217544882044, "lng": 341.3232421875},
            {"lat": 60.973107109199425, "lng": 323.08593750000006},
            {"lat": 64.20637724320852, "lng": 304.71679687500006},
            {"lat": 72.97762259146452, "lng": 303.64013671875006},
            {"lat": 73.12653976036125, "lng": 302.33276367187506},
            {"lat": 72.97762259146452, "lng": 303.64013671875006},
            {"lat": 73.94071394020898, "lng": 303.92578125},
            {"lat": 75.64498434288939, "lng": 306.5625},
            {"lat": 75.68850096854739, "lng": 305.46386718750006},
            {"lat": 75.91886229511059, "lng": 304.83764648437506},
            {"lat": 75.68850096854739, "lng": 305.46386718750006},
            {"lat": 75.34506171162991, "lng": 305.23315429687506},
            {"lat": 74.34593721891483, "lng": 302.32177734375},
            {"lat": 74.42282753118305, "lng": 300.79467773437506},
            {"lat": 75.52464464250062, "lng": 300.19042968750006},
            {"lat": 75.57125612747366, "lng": 299.48730468750006},
            {"lat": 75.37837872661018, "lng": 299.37194824218756},
            {"lat": 75.57125612747366, "lng": 299.48730468750006},
            {"lat": 76.47063313334205, "lng": 294.71923828125006},
            {"lat": 77.8741990405409, "lng": 294.23583984375006},
            {"lat": 78.03468777041608, "lng": 294.85107421875006},
            {"lat": 78.30940815087652, "lng": 294.23583984375006},
            {"lat": 78.73993797917838, "lng": 294.18090820312506},
            {"lat": 78.95034938035768, "lng": 291.90673828125006},
            {"lat": 78.93138260865257, "lng": 288.86352539062506},
            {"lat": 79.09573797855488, "lng": 288.62182617187506},
            {"lat": 78.93138260865257, "lng": 288.86352539062506},
            {"lat": 78.04834616078803, "lng": 286.94091796875006},
            {"lat": 77.37510457991046, "lng": 286.08398437500006},
            {"lat": 76.97272178439832, "lng": 285.63354492187506},
            {"lat": 76.65318152537525, "lng": 286.58386230468756},
            {"lat": 76.97272178439832, "lng": 285.63354492187506},
            {"lat": 76.44233211889495, "lng": 281.62353515625006},
            {"lat": 75.76164255318794, "lng": 281.53564453125006},
            {"lat": 75.4585892414553, "lng": 282.21679687500006},
            {"lat": 75.0248250056641, "lng": 281.81030273437506},
            {"lat": 75.12386410829582, "lng": 280.84899902343756},
            {"lat": 75.0248250056641, "lng": 281.81030273437506},
            {"lat": 73.92855278552236, "lng": 282.52441406250006},
            {"lat": 71.52490903732816, "lng": 281.18408203125006},
            {"lat": 68.84766505841037, "lng": 276.87744140625006},
            {"lat": 69.29696896468664, "lng": 275.30090332031256},
            {"lat": 68.84766505841037, "lng": 276.87744140625006},
            {"lat": 68.06509825098962, "lng": 265.47363281250006},
            {"lat": 69.10777677331498, "lng": 263.45214843750006},
            {"lat": 73.59679245247814, "lng": 245.06103515625003},
            {"lat": 74.75996761715504, "lng": 244.86328125000003},
            {"lat": 74.78449550320545, "lng": 245.78063964843753},
            {"lat": 74.75996761715504, "lng": 244.86328125000003},


        ],
        title: "Charter Ship",
        description: "",
        category: Categories.BOATS
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.BOATS + ' zeah',
        stops: [
            {"lat": 76.74291687836927, "lng": 305.9637451171875},
            {"lat": 82.06017004062555, "lng": 224.81872558593753},
            {"lat": 78.87958780617919, "lng": 203.719482421875},
        ],
        positions: [
            {"lat": 76.74291687836927, "lng": 305.9637451171875},
            {"lat": 76.15873701495826, "lng": 307.17773437500006},
            {"lat": 75.75894014501688, "lng": 307.06787109375},
            {"lat": 73.71427576088443, "lng": 303.72802734375006},
            {"lat": 69.85476220252649, "lng": 301.57470703125006},
            {"lat": 69.4421276134176, "lng": 294.06005859375006},
            {"lat": 67.941650035336, "lng": 265.58349609375006},
            {"lat": 73.49097744965937, "lng": 243.28125000000003},
            {"lat": 79.71860546904045, "lng": 239.89746093750003},
            {"lat": 82.08819592233121, "lng": 227.8125},
            {"lat": 82.06017004062555, "lng": 224.81872558593753},
            {"lat": 82.08819592233121, "lng": 227.8125},
            {"lat": 80.52483958061848, "lng": 229.70214843750003},
            {"lat": 79.15894345219259, "lng": 228.84521484375003},
            {"lat": 78.84457080539549, "lng": 222.64892578125003},
            {"lat": 78.88276578290562, "lng": 204.89501953125003},
            {"lat": 78.87958780617919, "lng": 203.719482421875},
        ],
        title: "Zeah Boat",
        description: "",
        category: Categories.BOATS
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.OTHER + 'muli_destination canoes',
        stops: [
            {"lat": 76.65191339742755, "lng": 318.27941894531256},
            {"lat": 78.1728342204629, "lng": 315.7196044921875},
            {"lat": 79.01857364724425, "lng": 309.73205566406256},
            {"lat": 80.20584929835839, "lng": 310.96252441406256},
            {"lat":82.9403268016951,"lng":311.85791015625006}
        ],
        positions: [
            {"lat": 76.65191339742755, "lng": 318.27941894531256},
            {"lat": 76.64049492108859, "lng": 318.08715820312506},
            {"lat": 76.90444330264503, "lng": 317.97729492187506},
            {"lat": 77.13516590007856, "lng": 317.69165039062506},
            {"lat": 77.34986665415636, "lng": 316.84020996093756},
            {"lat": 77.82100561582477, "lng": 316.49414062500006},
            {"lat": 77.9064655335818, "lng": 316.15905761718756},
            {"lat": 77.99590541527189, "lng": 315.91735839843756},
            {"lat": 78.0948965009262, "lng": 315.63171386718756},
            {"lat": 78.1728342204629, "lng": 315.7196044921875},
            {"lat": 78.0948965009262, "lng": 315.63171386718756},
            {"lat": 78.2301168948472, "lng": 314.26391601562506},
            {"lat": 78.32720375114681, "lng": 313.76953125},
            {"lat": 78.264802026314, "lng": 313.11035156250006},
            {"lat": 78.28489551177036, "lng": 312.62695312500006},
            {"lat": 78.40253749367973, "lng": 312.34130859375006},
            {"lat": 78.67108403464248, "lng": 312.01721191406256},
            {"lat": 78.74529976505912, "lng": 311.52832031250006},
            {"lat": 78.75494462937102, "lng": 310.85815429687506},
            {"lat": 78.778486629468, "lng": 310.24291992187506},
            {"lat": 78.89758448753416, "lng": 309.73205566406256},
            {"lat": 78.96402763947822, "lng": 309.65515136718756},
            {"lat": 79.02066624438999, "lng": 309.50683593750006},
            {"lat": 79.01857364724425, "lng": 309.73205566406256},
            {"lat": 79.02066624438999, "lng": 309.50683593750006},
            {"lat": 79.15894345219259, "lng": 309.30908203125006},
            {"lat": 79.21459369716146, "lng": 309.34204101562506},
            {"lat": 79.29856025794246, "lng": 309.77050781250006},
            {"lat": 79.35248724991952, "lng": 309.62219238281256},
            {"lat": 79.4283403025159, "lng": 309.26513671875006},
            {"lat": 79.64283716222033, "lng": 309.1058349609375},
            {"lat": 79.83365517648035, "lng": 309.18273925781256},
            {"lat": 79.981890541616, "lng": 309.62219238281256},
            {"lat": 80.07321244890505, "lng": 310.3802490234375},
            {"lat": 80.135517728182, "lng": 310.73730468750006},
            {"lat": 80.18620666179095, "lng": 310.81420898437506},
            {"lat": 80.20584929835839, "lng": 310.96252441406256},
            {"lat": 80.18620666179095, "lng": 310.81420898437506},
            {"lat": 80.26361497839086, "lng": 311.0504150390625},
            {"lat": 80.33673098695078, "lng": 311.055908203125},
            {"lat": 80.36803099414996, "lng": 310.73181152343756},
            {"lat": 80.46315010000394, "lng": 310.45166015625006},
            {"lat": 80.56184346817072, "lng": 310.1220703125},
            {"lat": 80.58164013978059, "lng": 309.957275390625},
            {"lat": 80.61752872092254, "lng": 309.91882324218756},
            {"lat":80.67911826095326,"lng":309.51782226562506},
            {"lat":82.9403268016951,"lng":311.85791015625006}


        ],
        title: "River Lum Log boats",
        description: "",
        category: Categories.OTHER
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.BOATS,
        stops: [
            {"lat":81.58203920979138,"lng":194.82604980468753},
            {"lat":81.6285525247791,"lng":193.17260742187503},
            {"lat":81.79091734319461,"lng":195.80932617187503},
            {"lat":81.2950290348757,"lng":197.2650146484375}
        ],
        positions: [
            {"lat":81.58203920979138,"lng":194.82604980468753},
            {"lat":81.6285525247791,"lng":193.17260742187503},
            {"lat":81.58203920979138,"lng":194.82604980468753},
            {"lat":81.79091734319461,"lng":195.80932617187503},
            {"lat":81.58203920979138,"lng":194.82604980468753},
            {"lat":81.2950290348757,"lng":197.2650146484375}
        ],
        title: "Boaty the boat",
        description: "",
        category: Categories.BOATS
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.OTHER,
        stops: [
            {"lat":77.6905283596315,"lng":273.31237792968756},
            {"lat":83.91671171854156,"lng":312.55554199218756},
            {"lat":79.80647104687989,"lng":308.19946289062506}
        ],
        positions: [
            {"lat":77.6905283596315,"lng":273.31237792968756},
            {"lat":83.91671171854156,"lng":312.55554199218756},
            {"lat":79.80647104687989,"lng":308.19946289062506}
        ],
        title: "Wilderness / Edgeville / Ardougne Lever",
        description: "",
        category: Categories.OTHER
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.CARPET_RIDES + ' carpet_ride',
        stops: [
            {"lat": 74.50522056937588, "lng": 322.89916992187506},
            {"lat": 74.5755046849982, "lng": 333.25927734375006},
            {"lat": 72.52648040023779, "lng": 325.54138183593756},
            {"lat": 73.31682283096622, "lng": 314.46716308593756},
        ],
        positions: [
            {"lat": 74.50522056937588, "lng": 322.89916992187506},
            {"lat": 74.5755046849982, "lng": 333.25927734375006},
            {"lat": 74.50522056937588, "lng": 322.89916992187506},
            {"lat": 72.52648040023779, "lng": 325.54138183593756},
            {"lat": 74.50522056937588, "lng": 322.89916992187506},
            {"lat": 73.31682283096622, "lng": 314.46716308593756},
            {"lat": 74.50522056937588, "lng": 322.89916992187506},

        ],
        title: "Desert Magic Carpets",
        description: "",
        category: Categories.CARPET_RIDES
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.CARPET_RIDES + ' carpet_ride',
        stops: [
            {"lat": 71.33949860626103, "lng": 325.140380859375},
            {"lat": 70.76158594641528, "lng": 328.71093750000006},
            {"lat": 68.41737291929003, "lng": 321.38854980468756},
            {"lat": 68.366801093914, "lng": 318.48815917968756},
        ],
        positions: [
            {"lat": 71.33949860626103, "lng": 325.140380859375},
            {"lat": 70.76158594641528, "lng": 328.71093750000006},
            {"lat": 71.33949860626103, "lng": 325.140380859375},
            {"lat": 68.41737291929003, "lng": 321.38854980468756},
            {"lat": 71.33949860626103, "lng": 325.140380859375},
            {"lat": 68.366801093914, "lng": 318.48815917968756},
            {"lat": 71.33949860626103, "lng": 325.140380859375}


        ],
        title: "Desert Magic Carpets",
        description: "",
        category: Categories.CARPET_RIDES
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.GNOME_GLIDERS,
        stops: [
            {"lat":80.12798624249334,"lng":266.80847167968756},
            {"lat":80.11762115289953,"lng":292.17590332031256},
            {"lat":79.28018409166839,"lng":323.56933593750006}, //Cant fly from here
            {"lat":76.26739068351604,"lng":320.71838378906256},
            {"lat":71.98408532635747,"lng":300.49255371093756},
            {"lat":68.17563985633973,"lng":283.41979980468756},
            {"lat":71.88016180394868,"lng":272.07641601562506},
        ],
        positions: [
        ],
        title: "Gnome Gliders",
        description: "",
        category: Categories.GNOME_GLIDERS
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.SPIRIT_TREES,
        stops: [
            {"lat":76.96776789443538,"lng":272.99377441406256},
            {"lat":79.47459894016727,"lng":266.73706054687506},
            {"lat":75.51228166167071,"lng":271.94458007812506},
            {"lat":80.21892275562932,"lng":314.5330810546875},
            {"lat":69.27559678639956,"lng":268.45092773437506}
        ],
        positions: [
        ],
        title: "Spirit Trees",
        description: "",
        category: Categories.SPIRIT_TREES
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.OTHER,
        stops: [
            {"lat":83.49656261479785,"lng":301.09130859375006},
            {"lat":82.93492373453886,"lng":309.39697265625006},
            {"lat":81.8300417529174,"lng":317.37304687500006},
            {"lat":81.37777346463594,"lng":312.71484375000006},
            {"lat":82.41693519891204,"lng":304.69482421875},
            {"lat":83.86291995653629,"lng":322.64648437500006}
        ],
        positions: [
        ],
        title: "Wilderness Obelisks",
        description: "",
        category: Categories.OTHER
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.BALLOON_TRANSPORT,
        stops: [
            {"lat":74.52282083394752,"lng":266.67663574218756},
            {"lat":78.28601082129987,"lng":289.57763671875006},
            {"lat":79.62999072905671,"lng":267.99499511718756},
            {"lat":77.57877763581085,"lng":297.19116210937506},
            {"lat":79.18164994073766,"lng":298.18542480468756},
            {"lat":79.87622823235664,"lng":322.04772949218756},

        ],
        positions: [

        ],
        title: "Balloon Transport System",
        description: "",
        category: Categories.BALLOON_TRANSPORT
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.MAGIC_MUSHROOMS,
        stops: [
            {"lat":83.5337864118568,"lng":346.82739257812506},
            {"lat":82.61299277110686,"lng":346.95373535156256},
            {"lat":82.62146305852085,"lng":352.29858398437506},
            {"lat":83.60454003128096,"lng":352.79296875}
        ],
        positions: [

        ],
        title: "Magic mushroom teleports",
        description: "",
        category: Categories.MAGIC_MUSHROOMS
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.OTHER,
        stops: [
            {"lat":83.23707339542034,"lng":214.93103027343753},
            {"lat":80.56994708559223,"lng":213.80493164062503},
            {"lat":82.3188108593499,"lng":205.10375976562503},
            {"lat":82.20379200648829,"lng":220.66314697265628},
            {"lat":81.39628312342714,"lng":209.18792724609375},
            {"lat":80.65729552731695,"lng":187.24548339843753}

        ],
        positions: [

        ],
        title: "Zeah Minecart system",
        description: "",
        category: Categories.OTHER
    },
    {
        iconUrl: require('../../../static/icons/misc/Transportation_icon.png'),
        className: Categories.BOATS,
        stops: [
            {"lat":83.70109641601667,"lng":350.72204589843756},
            {"lat":83.75331316974862,"lng":352.76550292968756},
            {"lat":83.02555186844917,"lng":350.46936035156256}
        ],
        positions: [
            {"lat":83.70109641601667,"lng":350.72204589843756},
            {"lat":83.75331316974862,"lng":352.76550292968756},
            {"lat":83.63079724346103,"lng":355.30883789062506},
            {"lat":83.22865871114548,"lng":358.4619140625},
            {"lat":82.47614570236671,"lng":358.143310546875},
            {"lat":82.07911723397942,"lng":352.47436523437506},
            {"lat":82.28533149704582,"lng":349.310302734375},
            {"lat":82.50056138051086,"lng":349.09057617187506},
            {"lat":82.63415038289305,"lng":349.486083984375},
            {"lat":82.65735415554457,"lng":349.94201660156256},
            {"lat":82.86430834427168,"lng":349.3048095703125},
            {"lat":83.02555186844917,"lng":350.46936035156256},
        ],
        title: "Fossil Island row boats",
        description: "",
        category: Categories.BOATS
    },
];