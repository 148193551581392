export const Regions = [
    /*{
        "label": "Wilderness",
        "points": [
            {
                "lat": 80.62558495575682,
                "lng": 299.00390625000006
            },
            {
                "lat": 84.33264230792068,
                "lng": 300.27832031250006
            },
            {
                "lat": 84.47829474142529,
                "lng": 330.42480468750006
            },
            {
                "lat": 81.56352330821746,
                "lng": 329.01855468750006
            },
            {
                "lat": 80.73234854648322,
                "lng": 325.89843750000006
            }
        ]
    }*/
];